/**
 * CardArticleFull
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'Components/Button';
import Image from 'Components/Image';
import Wysiwyg from 'Components/Wysiwyg';
import s from './CardArticleFull.module.scss';

class CardArticleFull extends React.PureComponent {
    render() {
        const {
            title,
            wysiwyg,
            link,
            image,
            modifier,
            imageAsBackground,
            fontawesome,
            redIcon
        } = this.props;

        const linkUrl = link ? link.href || link.url : '';

        const imageSizes = [
            '(min-width: 960px) 952px',
            '100vw',
        ];

        const hasImage = image && image.src;
        const hasBackground = (hasImage && imageAsBackground) || modifier;

        const classes = classNames(
            s['CardArticleFull'],
            {[s['CardArticleFull--HasBackground']]: hasBackground },
            {[s['CardArticleFull--ImageAsBackground']]: hasImage && imageAsBackground },
            {[s[`CardArticleFull--Background${modifier}`]]: modifier },
        );

        const iconClasses = classNames(
            s['CardArticleFull__Icon'],
            {[s['CardArticleFull__Icon--Red']]: redIcon },
            {[fontawesome]: fontawesome },
        );

        return (
            <article className={classes}>
                {hasBackground && (
                    <div className={s['CardArticleFull__Background']}>
                        {imageAsBackground && hasImage && (
                            <Image
                                {...image}
                                sizes={['100vw']}
                            />
                        )}
                    </div>
                )}
                <div className={s['CardArticleFull__Container']}>
                    <div className={s['CardArticleFull__Content']}>
                        {fontawesome && (
                            <i className={iconClasses} />
                        )}
                        <h2 className={s['CardArticleFull__Title']}>{title}</h2>
                        <Wysiwyg wysiwyg={wysiwyg} />

                        {linkUrl && link.text && (
                            <Link
                                {...link}
                                href={linkUrl}
                                cardTitle={title}
                            />
                        )}
                    </div>
                    {!imageAsBackground && hasImage && (
                        <div className={s['CardArticleFull__Image']}>
                            <Image
                                {...image}
                                sizes={imageSizes}
                            />
                        </div>
                    )}
                </div>
            </article>
        );
    }
}

CardArticleFull.defaultProps = {
    image: {},
    link: {
        text: '',
        href: '',
        isExternal: false,
    },
};

const Link = ({
    href = '',
    text = '',
    cardTitle = '',
}) => {
    if(!text || !href) {
        return null;
    }

    const expandLinkText = text === 'Läs mer';
    return (
        <Button href={href}>
            {text}
            {expandLinkText &&
                <span className="sr-only">{`om "${cardTitle}"`}</span>
            }
        </Button>
    );
};

Link.propTypes = {
    href: PropTypes.string,
    text: PropTypes.string,
    cardTitle: PropTypes.string,
};

export default CardArticleFull;
